<template>
  <div class="seal-material index-container">
    <common-header
      :is-show-menu="true"
      :client-width="clientWidth"
      current-menu="product"
      @openMobileMenu="openMobileMenu"
    />
    <seal-material :client-width="clientWidth"/>
    <common-footer :is-index="true" v-show="clientWidth>mobileWidth"/>
    <mobile-footer v-show="clientWidth<=mobileWidth"/>
    <slide-menu ref="slideMenu"/>
  </div>
</template>

<script>
import CommonHeader from '@components/CommonHeader.vue';
import CommonFooter from '@components/CommonFooter.vue';
import MobileFooter from '@components/MobileFooter.vue';
import SlideMenu from '@components/Menu.vue';
import SealMaterial from './components/SealMaterial.vue';

export default {
  name: 'SealMaterialIndex',
  components: {
    MobileFooter,
    CommonFooter,
    CommonHeader,
    SlideMenu,
    SealMaterial,
  },
  computed: {
    mobileWidth() {
      return this.$store.state.common.mobileWidth;
    },
  },
  data() {
    return {
      clientWidth: 1920,
      lineOne: ['日产鸿翔印台-1', '日产鸿翔印台-2', '日产鸿翔印台-3', '日产paper印台-1', '日产paper印台-2'],
      lineTwo: ['日产paper印台-3', '日产鸿翔印油红色', '日产鸿翔印油蓝色', '日产印油-光敏油（红）', '日产印油-光敏油（蓝）'],
      lineThree: ['日产印油-硬质印油', '日产印油-万能油', '新型树脂板', '橡胶', '脱模剂'],
      mobileLineOne: ['日产鸿翔印台-1', '日产鸿翔印台-2', '日产鸿翔印台-3'],
      mobileLineTwo: ['日产paper印台-1', '日产paper印台-2', '日产paper印台-3'],
      mobileLineThree: ['日产鸿翔印油红色', '日产鸿翔印油蓝色', '日产印油-光敏油（红）'],
      mobileLineFour: ['日产印油-光敏油（蓝）', '日产印油-硬质印油', '日产印油-万能油'],
      mobileLineFive: ['新型树脂板', '橡胶', '脱模剂'],
    };
  },
  mounted() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.clientWidth = document.body.clientWidth;
    },
    openMobileMenu() {
      this.$refs.slideMenu.onOpen();
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../less/mobileIndex.less";
</style>
